<svelte:options accessors/>
<script>
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();
	import { dataStore } from './stores.js';

	const getMethods = () => {
		return axios.get('/api/payment-methods', { params: { country_code: $dataStore.country_code } }).then(response => {
			dataStore.update(data => {
				data.methods = response.data;
				return data;
			})
		})
	}
	getMethods()
	export let selectedGroup;
	let selectedMethod = null;
	$: console.log(selectedGroup);

	function selectGroup(group) {
		console.log('group')
		console.log(group)
		selectedGroup = group;
		dispatch('selectGroup', {
			group,
			methods: $dataStore.methods
		});
	}

	export const advance = () => {
		return getMethods().then(() => selectGroup('credit_card'))
	}

</script>

<div>

	{#if Object.keys($dataStore.methods).length}
		<div class="w-full">
			{#each Object.keys($dataStore.methods) as paymentGroup, i}
				<div class="border border-gray-600 flex-grow
				           text-center h-16 flex pl-4 items-center cursor-pointer hover:bg-gray-300
				           w-full{i == 0 ? ' rounded-t' : ''}
						   {i !== (Object.keys($dataStore.methods).length - 1)? ' border-b-0' : ''}
						   {i == (Object.keys($dataStore.methods).length - 1)? ' rounded-b' : ''}
						   {paymentGroup == selectedGroup ? 'bg-gray-300' : ''}
						   "
					 on:click="{() => selectGroup(paymentGroup)}"
				>
					<img width="50" src="{$dataStore.methods[paymentGroup].icon}" alt="icono">

					<span class="pl-4 font-bold">{$dataStore.methods[paymentGroup].label}</span>
				</div>
			{/each}
		</div>
	{/if}

</div>