<script>
    export let callback;
    export let message;
</script>

<div class="h-full w-full flex items-center justify-center" style="min-height: 200px;">
    <button class="btn" on:click={callback}>
        {message}
    </button>
</div>

<style>

</style>