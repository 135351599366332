<script>
    import { paymentDataStore } from '../stores.js';
    import { dataStore } from '../stores.js';
    export let methods = { methods: [] };

    function selectMethod(method) {
        paymentDataStore.set({
            payment_method_id: method.id,
            payment_method_type: 'ticket',
            label: method.name,
        })
        dataStore.update(n => {
            n.view = 'confirm_view';
            return n;
        })
    }

</script>

<div>
    {#each methods.methods as method, i}
        <div class="h-16 flex items-center pl-4 cursor-pointer border border-gray-600
                    {i == 0 ? ' rounded-t' : ''}
                    {i == methods.methods.length - 1 ? ' rounded-b' : ''}
                    {$paymentDataStore.payment_method_id == method.id ? ' bg-gray-300' : ''}
                    {methods.methods.length != (i+1) ? ' border-b-0' : ''}"
             on:click={() => selectMethod(method) }
        >
            {method.name}
        </div>
    {/each}
</div>