<svelte:options accessors/>
<script>
	import { tick } from 'svelte';
	import CartForm from './CartForm.svelte';
	import PaymentMethodsTypeSelector from "./PaymentMethodsTypeSelector.svelte";
	import PaymentMethodSelector from "./PaymentMethodSelector.svelte";
	import ConfirmView from "./ConfirmView.svelte";
	import GoBack from './GoBack.svelte';
	import { dataStore, paymentDataStore } from './stores.js';

	export let reference_code;
	export let mercadopago_public_key;
	export let country_code;

	dataStore.update(n => {
		n.user.reference_code = reference_code
		n.mercadopago_public_key = mercadopago_public_key
		n.country_code = country_code
		return n
	})

	let paymentMethodParams = {};
	let selectedGroup;
	let selectedMethod = null;

	function setGroup(event) {
		dataStore.update(data => {
			data.view = 'select_method';
			return data;
		})
		paymentMethodParams = event.detail
		selectedGroup = event.detail.group
	}

	const selectedPillClasses = "bg-blue-500 text-white";
	const unselectedPillClasses = "bg-white text-blue-500";

	window.onpageshow = async function(event) {
		if (event.persisted) {
			dataStore.update(data => {
				data.view = 'form';
				return data;
			})
			await tick();
			document.querySelector('#svelte').scrollIntoView({behavior: 'smooth'});
		}
		
	};
</script>





{#if $dataStore.has_pedido == false}
	<div class="flex mb-4 md:w-8/12 mx-auto shadow-md">
		<div class="px-2 w-1/4 text-xs sm:text-sm text-center border border-r-0 border-blue-500 font-bold h-12 flex-grow
					flex items-center justify-center cursor-pointer rounded-l
					{$dataStore.view == 'form' ? selectedPillClasses : unselectedPillClasses}
					"
					on:click={() => $dataStore.view = 'form'}>
			Datos pedido
		</div>
		<div class="px-2 w-1/4 text-xs sm:text-sm text-center border border-r-0 border-blue-500 font-bold h-12 flex-grow flex items-center 						justify-center cursor-pointer
					{$dataStore.view == 'select_method_type' ? selectedPillClasses : unselectedPillClasses}
				"
			on:click={() => $dataStore.view = 'select_method_type'}>
			Tipo de pago
		</div>
		<div class="px-2 w-1/4 text-xs sm:text-sm text-center border border-r-0
					border-blue-500 font-bold h-12 flex-grow flex items-center 						
					justify-center cursor-pointer 
					{$dataStore.view == 'select_method' ? selectedPillClasses : unselectedPillClasses}"
			on:click={() => $dataStore.view = 'select_method'}>
			Metodo de pago
		</div>
		<div class="px-2 w-1/4 text-xs sm:text-sm text-center border border-blue-500
					font-bold h-12 flex-grow flex items-center justify-center 
					cursor-pointer rounded-r
					{$dataStore.view == 'confirm_view' ? selectedPillClasses : unselectedPillClasses}"
			on:click={() => $dataStore.view = 'confirm_view'}>
			Verificar
		</div>
	</div>
{/if}

<div class="px-0 mb-4" style="min-height: 192px">
	{#if $dataStore.view == 'form'}
		
		{#if $dataStore.has_pedido == false}
			<CartForm></CartForm>
		{:else}
			<GoBack callback="{() => { history.forward() } }" message="Ya tu pedido fue creado"></GoBack>
		{/if}
	{/if}
	{#if $dataStore.view == 'select_method_type'}
		{#if $dataStore.user_data_ready}
			<PaymentMethodsTypeSelector on:selectGroup={setGroup} selectedGroup={selectedGroup} />
		{:else}
			<GoBack callback="{() => { $dataStore.view='form'} }" message="Llene sus datos primero!"></GoBack>
		{/if}
	{/if}
	{#if $dataStore.view == 'select_method'}
		{#if paymentMethodParams.group}
			<PaymentMethodSelector {...paymentMethodParams}
								   selectedMethod={selectedMethod} />
		{:else}
			<GoBack callback="{() => { $dataStore.view='select_method_type'} }" message="Seleccione un tipo de pago!"></GoBack>
		{/if}
	{/if}

	{#if $dataStore.view == 'confirm_view'}
		{#if $paymentDataStore.payment_method_id}
			<ConfirmView />
		{:else}
			<GoBack callback="{() => { $dataStore.view='select_method'} }" message="Seleccione un medio de pago!"></GoBack>
		{/if}
	{/if}
</div>
