import { writable, derived } from 'svelte/store';

export const one = writable(true);
export const two = writable(true);
export const dataStore = writable({
    view: 'form',
    user: {
        email: '',
        first_name: '',
        last_name: '',
        sex: '',
        phone: '',
        local_id_type: '',
        local_id: '',
        address: '',
        address_complement: '',
        wants_account: true,
        municipio_id: null,
        reference_code: null,
    },
    user_data_ready: false,
    total_cart: null,
    mercadopago_public_key: null,
    methods: {},
    has_pedido: false,
    country_code: null

});
export const paymentDataStore = writable({
    payment_method_id: null,
    label: null,
});


export const creditCardForm = writable({
    cardNumber: '4242424242424242',
    cardholderName: 'Pedro Perez',
    docType: null,
    installments: 1,
    installments_options: [],
    docNumber: '1120741',
    cardExpirationMonth: '06',
    cardExpirationYear: '25',
    securityCode: '800',
    token: '',
    acceptance_token: ''
})

export const confirmButtonText = derived(paymentDataStore, ($paymentDataStore) => {
    let text = 'Pagar';
    switch ($paymentDataStore.payment_method_type) {
        case 'credit_card':
             text = 'Pagar con la tarjeta';
            break;
        case 'pse':
            text = 'Ir a PSE';
            break;
        case 'ticket':
            text = 'Generar recibo de pago';
            break;
    }
    return text;
});