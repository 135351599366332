<script>
	import TicketPayment from './payment_methods/TicketPayment.svelte'
	import PsePayment from './payment_methods/PsePayment.svelte'
	import CreditCardPayment from './payment_methods/CreditCardPayment.svelte'
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();
	export let group;
	export let methods = {};

	let currentView = 'groupsView';
	export let selectedMethod;


</script>

<div>
	<div class="border-0 border-red-500">
		{#if group == 'ticket'}
			<TicketPayment selectedMethod={selectedMethod} methods={methods[group]} />
		{/if}

		{#if group == 'bank_transfer'}
			<PsePayment methods={methods[group]} />
		{/if}

		{#if group == 'credit_card'}
			<CreditCardPayment />
		{/if}
	</div>
</div>