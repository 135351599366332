<svelte:options accessors/>
<script>
    import { paymentDataStore } from '../stores.js';
    import { dataStore } from '../stores.js';
    export let methods = { methods: [] };
    let data;
    let selected = "";
    dataStore.subscribe(value => {
        data = value;
    });
    let isLoading = false;
    let pse = methods.methods[0];
    let financial_institutions =  methods.methods[0].financial_institutions
    let paymentData;
    paymentDataStore.subscribe(value => {
        paymentData = value
        if (value.financial_institution_id) {
            let financial_institution = financial_institutions.find(fi => {
                return fi.id == value.financial_institution_id;
            })
            selected = financial_institution
        }
    });
    function setFinancialInsitution() {
        paymentDataStore.set({
            payment_method_id: selected ? 'pse' : null,
            payment_method_type: 'pse',
            label: `Pse, ${selected.description}`,
            financial_institution_id: selected.id
        });
    }
    function goToConfirm() {
        dataStore.update(data => {
            data.view = 'confirm_view';
            return data
        })
    }

</script>

<div class="border border-gray-400 rounded shadow-md flex justify-center py-8">
    <div class="w-full sm:w-1/2">
        <div class="mb-4">
            <label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800"  for="installments">Banco</label>
            <select name="financial_institution"
                    class="w-full border border-gray-600 bg-white h-10 text-center rounded"
                    bind:value={selected}
                    on:change="{setFinancialInsitution}">
                <option value="">Seleccionar tu banco</option>
                {#each financial_institutions as financial_institution}
                    <option value="{financial_institution}">{financial_institution.description}</option>
                {/each}
            </select>
        </div>

        <button
                class="btn w-full { !selected ? 'opacity-50 cursor-not-allowed' : '' }
                       {isLoading ? 'btn-spin' : ''}"
                on:click={goToConfirm}
                disabled={!selected}
                type="submit" >Continuar</button>
    </div>
</div>
