<svelte:options accessors/>
<script>
	import { dataStore } from './stores.js'
	import { tick } from 'svelte';
	function getDeps() {
		return axios.get('/api/departamentos').then(response => {
			deps = response.data
		})
	}

	function getMuns() {
		dataStore.update(n => {
			n.user.municipio_id = ''
			return n
		})
		muns = []
		axios.get('/api/municipios?departamento=' + selectedDep)
				.then(response => {
					muns = response.data
				})
	}

	function submitCartData() {
		isLoading = true
		return axios.post('/api/cart-data', {...$dataStore.user, country_code: $dataStore.country_code}).then(async response => {
			isLoading = false
			dataStore.update(data => {
				data.user_data_ready = true
				data.view = 'select_method_type';
				data.total_cart = response.data.total_cart;
				return data
			})
			setUserData(response.data.user)
			await tick();
			document.querySelector('#svelte').scrollIntoView({behavior: 'smooth'});
			
		}).catch(error => {
			isLoading = false
			dataStore.update(data => {
				data.user_data_ready = false
				return data
			})
			serverError(error)
		})
	}

	function getUserData() {
		return axios.get('/api/get-cart-data',
				{ params: { country_code: $dataStore.country_code ,cart_id: $dataStore.user.reference_code, timestamp: new Date().getTime() } })
				.then(response => {
					setUserData(response.data)
				})
	}

	function setUserData(userData) {
		for (let k in userData) {
				switch(k) {
					case 'municipio':
						selectedDep = userData[k].departamento.id
						muns.push(userData[k])
						muns = muns
						dataStore.update(data => {
							data.user.municipio_id = userData[k].id
							data.user.municipio = userData[k]
							return data;
						})
						break;
					case 'has_pedido':
						dataStore.update(data => {
							data.has_pedido = userData[k]
							return data;
						})
						break;

					default:
						if (userData[k]) {
							dataStore.update(data => {
								data.user[k] = userData[k]
								return data;
							})
						}
				}
				
			}
	}

	let selectedDep = '';
	let deps = [];
	let muns = [];
	let isLoading = false;
	getDeps().then(() => getUserData())
	export const advance = () => {
		return getDeps().then(() => getUserData()).then(() => submitCartData())
	}


</script>


<div class="border border-gray-400 shadow-md rounded px-4 py-4">

	<div class="columns is-multiline is-mobile">
		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Email</label>
			<div class="control">
				<input class="input" type="email" bind:value={$dataStore.user.email}>
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Nombres</label>
			<div class="control">
				<input class="input" type="text" bind:value={$dataStore.user.first_name}>
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Apellidos</label>
			<div class="control">
				<input class="input" type="text" bind:value={$dataStore.user.last_name} >
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800"s>Sexo</label>
			<div class="control">
				<div class="select is-fullwidth">
					<select bind:value={$dataStore.user.sex}>
						<option value="">Selecciona tu género</option>
						<option value="F">Mujer</option>
						<option value="M">Hombre</option>
						<option value="O">Otro</option>
						<option value="">Prefiero no decirlo</option>
					</select>
				</div>
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Celular</label>
			<div class="control">
				<input class="input" type="number" bind:value={$dataStore.user.phone}>
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
            <label class="">Tipo de identificación</label>
            <div class="control">
                <div class="select is-fullwidth">
                    <select v-model="user.local_id_type" bind:value={$dataStore.user.local_id_type}>
                        <option value="">Seleccionar</option>
                        <option value="cc">Cedula</option>
                        <option value="ti">Tarjeta de identidad</option>
                        <option value="ce">Cedula de extranjería</option>
                        <option value="otro">Otro</option>
                    </select>
                </div>
            </div>
        </div>

         <div class="column is-12-mobile is-6-tablet is-3-desktop field">
            <label for="local_id_field">Numero de identificación</label>
            <div class="control">
                <input class="input" id="local_id_field" type="number" v-model="user.local_id" bind:value={$dataStore.user.local_id}>
            </div>
        </div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Departamento</label>
			<div class="control">
				<div class="select is-fullwidth">
					<select bind:value={selectedDep} on:change={getMuns} >
						<option value="">Seleccionar</option>
						{#each deps as dep}
							<option value={dep.id}>{dep.departamento}</option>
						{/each}
					</select>
				</div>
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Municipio</label>
			<div class="control">
				<div class="select is-fullwidth">
					<select bind:value={$dataStore.user.municipio_id}>
						{#if muns.length}
							<option  value="">Seleccionar</option>
						{:else}
							<option value="">Seleccione un departamento</option>
						{/if}

						{#each muns as mun}
							<option value={mun.id}>{mun.municipio}</option>
						{/each}
					</select>
				</div>
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Dirección</label>
			<div class="control">
				<input class="input" type="text" bind:value={$dataStore.user.address} placeholder="calle 12 # 34 - 34">
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Dirección complemento</label>
			<div class="control">
				<input class="input" type="text" bind:value={$dataStore.user.address_complement} placeholder="Apto 110, casa, manzana, interior 8">
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">Crear una cuenta: </label>
			<div class="control">
				<div class="select is-fullwidth">
					<select bind:value={$dataStore.user.wants_account}>
						<option value={true}>Crear</option>
						<option value={false}>Continuar sin una cuenta</option>
					</select>
				</div>
			</div>
		</div>

		<div class="column is-12-mobile is-6-tablet is-3-desktop field">
			<label class="form-label flex text-sm leading-5 font-medium mb-0 text-gray-800">&nbsp;</label>
			<div class="control">
				<button
						class="btn w-full {isLoading ? 'btn-spin':'' }"
						on:click={submitCartData}
						type="submit" >Continuar</button>
			</div>
		</div>


	</div>

</div>
