<script>
	import { dataStore, paymentDataStore, confirmButtonText } from './stores.js';
	document.querySelector('#svelte').scrollIntoView({behavior: 'smooth'});
	let isLoading = false;
	function createPayment() {
		isLoading = true
		axios.post('/api/create-payment', {
					reference_code: $dataStore.user.reference_code,
					payment_method_data: $paymentDataStore,
					country_code: $dataStore.country_code
				}).then(response => {
					window.location.href = response.data.url;
					isLoading = false;
				}).catch(error => {
					serverError(error);
					isLoading = false;
				})
	}

</script>

<div>
	<div class="p-4 text-center">
		<h2 class="text-2xl font-bold">Revisa que todo esta correcto</h2>

		<div class="flex justify-center">
			<table class="table is-bordered is-narrow is-striped my-4 mx-auto flex-grow md:flex-grow-0 md:w-1/2">
				<tbody>
				<tr>
					<th class="text-right">
						Metodo de pago
					</th>
					<td class="text-left w-1/2">
						{$paymentDataStore.label}
					</td>
				</tr>
				{#if $paymentDataStore.installments}
					<tr>
						<th class="text-right">
							Cuotas
						</th>
						<td class="text-left w-1/2">
							{$paymentDataStore.installments}
						</td>
					</tr>
				{/if}
				<tr>
					<th class="text-right">
						Nombres
					</th>
					<td class="text-left w-1/2">
						{$dataStore.user['first_name']}
					</td>
				</tr>
				<tr>
					<th>
						Apellidos
					</th>
					<td>
						{$dataStore.user['last_name']}
					</td>
				</tr>
				<tr>
					<th>
						Sexo
					</th>
					<td>
						{$dataStore.user['sex']}
					</td>
				</tr>
				<tr>
					<th>
						Telefono
					</th>
					<td>
						{$dataStore.user['phone']}
					</td>
				</tr>
				<tr>
					<th>
						Dirección
					</th>
					<td>
						{$dataStore.user['address']}
					</td>
				</tr>
				<tr>
					<th>
						Dirección complemento
					</th>
					<td>
						{$dataStore.user['address_complement']}
					</td>
				</tr>
				<tr>
					<th>
						Departamento
					</th>
					<td>
						{#if $dataStore.user.municipio}
							{$dataStore.user['municipio']['departamento']['departamento']}
						{/if}
					</td>
				</tr>
				<tr>
					<th>
						Municipio
					</th>
					<td>
						{#if $dataStore.user.municipio}
							{$dataStore.user['municipio']['municipio']}
						{/if}
					</td>
				</tr>
				</tbody>
			</table>
		</div>


		<button class="btn w-full {isLoading ? 'btn-spin' : ''}" on:click={createPayment}>
			{$confirmButtonText}
		</button>
	</div>
</div>