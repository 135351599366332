<script>
    import {creditCardForm, dataStore, paymentDataStore} from './../stores.js';

    let intervalCount = 0
    let interval = setInterval(() => {
        console.log('interval')
        intervalCount++
        if (window.Mercadopago) {
            clearInterval(interval)
            initMercadoPago();
        }
        if (intervalCount > 10) {
            clearInterval(interval)
        }

    }, 100)

    function initMercadoPago() {
        window.Mercadopago.setPublishableKey($dataStore.mercadopago_public_key);
    }

    let isLoading = false;

    function guessPaymentMethod() {
        if ($creditCardForm.cardNumber.length >= 6) {
            let bin = $creditCardForm.cardNumber.substring(0, 6);
            window.Mercadopago.getPaymentMethod({
                "bin": bin
            }, setPaymentMethod);
        }
    };

    function setPaymentMethod(status, response) {
        if (status == 200) {
            paymentDataStore.update(data => {
                data.payment_method_id = response[0].id;
                return data
            })
            getInstallments();
        } else {
            alert(`payment method info error: ${response}`);
        }
    }

    function getInstallments() {
        window.Mercadopago.getInstallments({
            "payment_method_id": $paymentDataStore.payment_method_id,
            "amount": parseFloat($dataStore.total_cart)

        }, function (status, response) {
            if (status == 200) {
                let installments_options = [];
                response[0].payer_costs.forEach(installment => {
                    installments_options.push({
                        text: installment.recommended_message,
                        value: installment.installments
                    })
                });
                creditCardForm.update(n => {
                    n.installments = installments_options[0].value
                    n.installments_options = installments_options
                    return n
                })
            } else {
                alert(`installments method info error: ${response}`);
            }
        });
    }


    function submit() {
        isLoading = true
        var form = document.querySelector('#pay');
        if ($dataStore.country_code == 'co') {
            window.Mercadopago.createToken(form, sdkResponseHandler);
        }

        if ($dataStore.country_code == 'us') {
            axios.post('/api/cart/prepare-credit-card', {
                "number": $creditCardForm.cardNumber,
                "cvc": $creditCardForm.securityCode,
                "exp_month": $creditCardForm.cardExpirationMonth,
                "exp_year": $creditCardForm.cardExpirationYear,
                "card_holder": $creditCardForm.cardholderName
            }).then(response => {

                console.log(response.data)

                creditCardForm.update(n => {
                    n.token = response.data.provider_card_token
                    n.acceptance_token = response.data.acceptance_token
                    return n
                })


                paymentDataStore.set({
                    payment_method_id: 'wompi_cc',
                    payment_method_type: 'credit_card',
                    label: `Tarjeta: ${$paymentDataStore.payment_method_id}`,
                    credit_card_token: $creditCardForm.token,
                    installments: $creditCardForm.installments,
                    acceptance_token: response.data.acceptance_token,
                });


                dataStore.update(n => {
                    n.view = 'confirm_view';
                    return n
                })
            }).finally(() => isLoading = false)
        }

    };

    function sdkResponseHandler(status, response) {
        isLoading = false
        console.log('sdkResponseHandler')
        if (status != 200 && status != 201) {
            alerts.warning("Verificar el formulario");
        } else {
            $creditCardForm.token = response.id
            paymentDataStore.set({
                payment_method_id: $paymentDataStore.payment_method_id,
                payment_method_type: 'credit_card',
                label: `Tarjeta: ${$paymentDataStore.payment_method_id}`,
                token: $creditCardForm.token,
                installments: $creditCardForm.installments,
            });
            dataStore.update(n => {
                n.view = 'confirm_view';
                return n
            })
        }
    };

</script>

<form on:submit|preventDefault={submit} method="post" id="pay" name="pay"
      class="border border-gray-400 rounded shadow-md py-8">
    <h2 class="px-4 text-2xl font-bold">Llena los datos de tu tarjeta de credito</h2>
    <div class="py-4 px-4 hidden">
        <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800"
               for="description">Descripción</label>
        <input class="border border-gray-600 rounded w-full h-10"
               type="text"
               name="description" id="description"
               value="pedido colgafas"/>
    </div>
    <div class="py-4 px-4 hidden">
        <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="transaction_amount">Monto a
            pagar</label>
        <input class="border border-gray-600 rounded w-full h-10"
               name="transaction_amount"
               id="transaction_amount"
               bind:value={$dataStore.total_cart}
        />
    </div>


    <div class="flex flex-wrap border-0 border-red-400">
        <div class="border-0 border-blue-500 w-full sm:w-1/2">
            <div class="py-4 px-4">
                <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="cardNumber">Número
                    de la tarjeta</label>
                <input class="border border-gray-600 rounded w-full h-10"
                       type="text" id="cardNumber"
                       data-checkout="cardNumber"
                       onselectstart="return false"
                       onpaste="return false"
                       onCopy="return false"
                       onCut="return false"
                       onDrag="return false"
                       onDrop="return false"
                       autocomplete=off
                       on:keyup={guessPaymentMethod}
                       on:change={guessPaymentMethod}
                       bind:value={$creditCardForm.cardNumber}
                />
            </div>
            <div class="py-4 px-4">
                <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="cardholderName">Nombre
                    y apellido</label>
                <input class="border border-gray-600 rounded w-full h-10"
                       bind:value={$creditCardForm.cardholderName}
                       type="text"
                       id="cardholderName"
                       data-checkout="cardholderName"/>
            </div>
            <div class="py-4 px-4">
                <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="docType">Tipo de
                    documento</label>
                <select id="docType"
                        bind:value={$creditCardForm.docType}
                        data-checkout="docType"
                        class="border border-gray-600 bg-white h-10 w-full text-center rounded"
                >
                    <option value="CC">C.C.</option>
                    <option value="CE">C.E.</option>
                    <option value="NIT">NIT</option>
                    <option value="Otro">Otro</option>
                </select>
            </div>
            <div class="py-4 px-4">
                <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="docNumber">Número
                    de documento</label>
                <input bind:value={$creditCardForm.docNumber}
                       class="border border-gray-600 rounded w-full h-10"
                       type="text"
                       id="docNumber"
                       data-checkout="docNumber"/>
            </div>
        </div>


        <div class="border-0 border-blue-500 w-full sm:w-1/2">
            <div class="py-4 px-4">
                <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800"
                       for="cardExpirationMonth">Mes/Año de vencimiento</label>
                <div class="flex w-32 border rounded border-gray-600">
                    <input bind:value={$creditCardForm.cardExpirationMonth}
                           class="border-0 w-full rounded h-10 text-right outline-none"
                           placeholder="MM"
                           type="text" id="cardExpirationMonth"
                           data-checkout="cardExpirationMonth"
                           onselectstart="return false"
                           onpaste="return false"
                           onCopy="return false"
                           onCut="return false"
                           onDrag="return false"
                           onDrop="return false"
                           autocomplete=off/>
                    <span class="flex items-center text-xl px-1">/</span>
                    <input bind:value={$creditCardForm.cardExpirationYear}
                           class="border-0 w-full rounded h-10 text-left outline-none"
                           placeholder="AA"
                           type="text" id="cardExpirationYear"
                           data-checkout="cardExpirationYear"
                           onselectstart="return false"
                           onpaste="return false"
                           onCopy="return false"
                           onCut="return false"
                           onDrag="return false"
                           onDrop="return false"
                           autocomplete=off/>

                </div>
            </div>
            <div class="py-4 px-4">
                <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="securityCode">Código
                    de seguridad</label>
                <input bind:value={$creditCardForm.securityCode}
                       class="border border-gray-600 rounded w-full h-10"
                       type="text"
                       id="securityCode"
                       data-checkout="securityCode"
                       onselectstart="return false"
                       onpaste="return false"
                       onCopy="return false"
                       onCut="return false"
                       onDrag="return false"
                       onDrop="return false"
                       autocomplete=off/>
            </div>
            <div class="py-4 px-4">
                <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="installments">Cuotas</label>
                <select id="installments"
                        bind:value={$creditCardForm.installments}
                        class="w-full border border-gray-600 bg-white h-10 text-center rounded"
                        name="installments">
                    {#each $creditCardForm.installments_options as installment, i (installment.value)}
                        <option value="{installment.value}">{installment.text}</option>
                    {/each}
                </select>
            </div>

        </div>
    </div>

    <div class="py-4 px-4 hidden">
        <label class="flex form-label text-sm leading-5 font-medium mb-0 text-gray-800" for="email">Email</label>
        <input class="border border-gray-600 rounded w-full h-10" type="email" id="email" name="email"
               value="test@test.com"/>
    </div>
    <input type="hidden" name="payment_method_id" id="payment_method_id"
           bind:value={$paymentDataStore.payment_method_id}/>
    <div class="p-2">
        <button class="btn w-full {isLoading ? 'btn-spin' : ''}" type="submit">Continuar</button>
    </div>
</form>
